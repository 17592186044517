<template>



  <div class="view-trip">
    <main-nav />
    <main-titlebar myclass="purpleBar" showback="true" rbutton="editcontact" :id="id"/>

    <div  class="trip-view-wrapper" style="display:block;">    
      <div v-for="contact in contacts" :key="contact.id" >

 
          <div class="form-group">
            <div class="col-2-title textAlignCenter">
                Naam
            </div>
            <div class="col-2 textAlignLeft ">
              {{contact.name}}
            </div>
          </div>
          <div class="form-group">
            <div class="col-2-title textAlignCenter">
                Email
            </div>
            <div class="col-2 textAlignLeft ">
              {{contact.email}}
            </div>
          </div>
          <div class="form-group">
            <div class="col-2-title textAlignCenter">
                Telefoon
            </div>
            <div class="col-2 textAlignLeft ">
              {{contact.phone}}
            </div>
          </div>
          <div class="form-group">
            <div class="col-2-title textAlignCenter">
                Adres
            </div>
            <div class="col-2 textAlignLeft ">
              {{contact.address}}
              {{contact.zip}} {{contact.city}}
            </div>
          </div>
          <div class="form-group">
            <div class="textAlignCenter col-1-title " style="float:left;width:100%;">
                Notitie
            </div>               
          </div>
          <div class="form-group">
            <div class="textAlignCenter col-1" style="float:left;width:100%;">
                {{contact.note}}
            </div>               
          </div>                                      

        </div>
    </div>
  </div>

</template>
<script>

import ContactService from "../../services/contact-service";

export default {
  name:'view-circle',
  data() {
    var status = 'loading'
    var contacts;
    var id = this.$route.params.id;
    return { contacts, status, id }
  },
  methods: {
    offerTrip(id) {
      this.$router.push('/trips/offer/'+id);    
    }
 
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    ContactService.getContact(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.contacts = response.data;
      
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  computed: {

    isPublic() {
      return this.circles[0].id;
    },
  },
  
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>